<template>
  <div id="project-recycle" v-cloak v-loading.fullscreen.lock="loading">
    <div class="header bg_white">
      <h1 class="titH">
        {{ $t("projectManagement.projectsList") }}
      </h1>
    </div>
    <div class="project-box">
      <h1 class="project-tit">{{ $t("projectRecycle.deletedProject") }}</h1>
      <div class="project-inner_box">
        <div v-for="(item, index) in list" :key="index" class="project-item">
          <div class="flex-between hand" @click="viewProject(item.id)">
            <img
              :src="'api/Common/viewCrmTaskImg' + '?path=' + item.project_photo"
              class="project-img"
              v-if="item.project_photo !== ''"
            />
            <span class="project-icon" v-else>
              <i class="iconfont icon-xiangmu1"></i>
            </span>
            <div class="flex-between task-status-hand">
              <div class="flex-a-b">
                <template
                  v-if="Date.parse(item.want_end_time) < new Date().getTime()"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('projectManagement.overdue')"
                    placement="top-start"
                  >
                    <span class="red-text project-name ellipsis">
                      {{ item.project_name }}
                    </span>
                  </el-tooltip>
                </template>
                <span v-else class="project-name ellipsis">
                  {{ item.project_name }}
                </span>
                <span :class="getStatusClass(item.status)">
                  -{{ formateStatus(item.status) }}
                </span>
              </div>
              <div>
                <el-popover placement="right" width="400" trigger="hover">
                  <el-cascader-panel
                    :options="item.userClass"
                    class="user-cascader"
                  >
                    <template slot-scope="{ node, data }">
                      <span>{{ data.label }}</span>
                      <span v-if="!node.isLeaf">
                        {{ data.children.length }}
                      </span>
                    </template>
                  </el-cascader-panel>
                  <div slot="reference">
                    <i class="iconfont icon-qunzu"></i>
                    <span class="project-num">{{ item.num_total }}</span>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
          <div
            class="project-info multi-line hand"
            @click="viewProject(item.id)"
          >
            {{ item.info }}
          </div>
          <div class="mb16 flex-between time-box">
            <div>
              <i class="el-icon-date"></i>
              <span>
                {{
                  item.want_start_time
                    ? getFormateTime(item.want_end_time)
                    : $t("projectManagement.unfinished")
                }}
              </span>
            </div>
            <div>
              <i class="el-icon-time"></i>
              <span class="">
                {{
                  item.want_end_time
                    ? getFormateTime(item.want_end_time)
                    : $t("projectManagement.unfinished")
                }}</span
              >
            </div>
          </div>
          <div class="tag-box">
            <span
              v-for="(tagItem, index) in item.tags_name"
              :style="{ backgroundColor: tagItem.value }"
              :key="index"
            >
              {{ tagItem.name }}
            </span>
          </div>
          <div class="handel-box flex-d-c">
            <zc-icon-btn
              :content="$t('projectRecycle.restoreProject')"
              btn_type="warning"
              btn_class="form_button"
              icon="icon-jihuoxiangmu"
              v-if="rightShow('CrmTask@restoreProject')"
              @click="restoreProject(item.id)"
            ></zc-icon-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTabParam, setTabParam } from "@/utils/common";
import { toMessage } from "@/utils/message";
import ZcIconBtn from "@/components/ZcIconBtn.vue";

export default {
  name: "ProjectRecycle",
  components: {
    ZcIconBtn,
  },
  data() {
    return {
      loading: true,
      visible: false,
      invite_type: "",
      invite_user_ids: "",
      selectedArray: [],
      list: [],
      userOptions: [],
      selectedAll: false,
      projectName: "",
      info: "",
      user: "",
      desc: "",
      id: "",
      dialogTit: "",
      startTime: "",
      endTime: "",
      isDisable: false,
      uploadAccept: ".png, .jpg, .jpeg, .gif", //上传限制文件
      size: 1 * 1024 * 1024,
      bigImgVisible: false,
      bigImgUrl: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
    };
  },
  inject: ["rightShow"],
  methods: {
    formateStatus: function (value) {
      let taskStatus = {
        1: "projectManagement.unfinished",
        2: "projectManagement.notStarted",
        3: "projectManagement.working",
        4: "projectManagement.finished",
      };
      return this.$t(taskStatus[value]);
    },
    // 状态的class
    getStatusClass: function (value) {
      let statusClass = {
        1: "gray-text",
        2: "yellow-text",
        3: "blue-text",
        4: "black-text",
      };
      return statusClass[value];
    },
    getFormateTime(time) {
      if (time) {
        return time.substring(16, 0);
      } else {
        return "";
      }
    },
    //获取列表
    getInfo() {
      this.loading = true;
      this.axios
        .post("/home/crmTask/getProjectRecycleList")
        .then((res) => {
          this.loading = false;
          this.list = res.data.data.list;
        })
        .catch(() => {});
    },
    //还原项目
    restoreProject(id) {
      this.$confirm(
        this.$t("projectRecycle.confirmRestoreProject"),
        this.$t("tips"),
        {
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
          type: "warning",
        }
      ).then(() => {
        this.loading = false;
        this.axios
          .post("/home/crmTask/restoreProject", {
            id: id,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.getInfo();
          })
          .catch(() => {});
      });
    },
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    // 详情页
    viewProject: function (id) {
      if (this.rightShow("CrmTask@projectInfo")) {
        this.addTab(
          "CrmTask",
          "projectInfo",
          "<tags:lang>项目</tags:lang>-<tags:lang>详情</tags:lang>",
          { id: id, is_view: 1 }
        );
      }
    },

    showTab() {
      let tabData = getTabParam();
      if (tabData.isTab) {
        this.getInfo();
      }
      tabData.isTab = false;
      setTabParam(tabData);
    },
  },

  created: function () {
    // let tabData = getTabParam();
    // if (tabData.isTab) {
    //   this.showTab();
    // } else {
    //   this.getInfo();
    // }
    this.getInfo();
  },
};
</script>

<style scoped>
@import url("//at.alicdn.com/t/font_1715862_a2wqbf03b9a.css");
.header {
  height: 89px;
  padding: 28px 32px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
.bg_white {
  background: #fff;
}
.recycle-btn {
  padding: 5px 16px;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgba(255, 87, 92, 1);
  color: #ff575c;
  cursor: pointer;
}
.titH {
  font-size: 24px;
  font-weight: 600;
}
.add-btn {
  padding: 6px 10px;
  font-size: 14px;
  color: #000000;
  line-height: 1.5;
  cursor: pointer;
}
.dia-span {
  display: inline-block;
  width: 100px;
  text-align: right;
}
#project-recycle {
  background: #eaeaea;
  padding: 6px !important;
}
.grid-content {
  border-radius: 4px;
  height: 126px;
  min-width: 800px;
}

.w220 {
  width: 220px;
}
.project-box {
  padding: 24px 32px;
  padding-bottom: 8px;
  background: #fff;
  margin-bottom: 16px;
}
.project-tit {
  font-size: 18px;
  color: #1c1c1e;
  margin-bottom: 16px;
  font-weight: 600;
}
.project-inner_box {
  display: flex;
  flex-wrap: wrap;
}
.project-item {
  width: 310px;
  height: 450px;
  padding: 22px;
  border: 1px solid rgba(225, 225, 225, 1);
  margin-right: 24px;
  margin-bottom: 24px;
  font-size: 14px;
  position: relative;
}
.project-item:last-child {
  margin-right: 0;
}
.project-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.project-info {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 1.5;
  margin-top: 13px;
  margin-bottom: 25px;
  height: 105px;
}
.multi-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.mb16 {
  margin-bottom: 16px;
}
.project-num {
  color: #ff7315;
  font-size: 14px;
  margin-left: 3px;
}
.light-tit {
  opacity: 0.6;
}
.handel-box {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 44px;
  padding-left: 22px;
  padding-right: 22px;
}
.mr40 {
  margin-right: 40px;
}
.project-icon {
  color: #fff;
  background: #ff7315;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: inline-block;
  line-height: 32px;
  text-align: center;
}
.progress-box {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  padding-left: 23px;
  padding-right: 23px;
  display: flex;
  align-items: center;
}
.progress-box span {
  width: 46px;
  height: 7px;
  margin-right: 8px;
}
.progress-box span:last-child {
  margin-right: 0;
}
.done-span {
  background: rgba(255, 115, 21, 1);
}
.undone-span {
  background: rgba(255, 115, 21, 0.1);
}
.time-box {
  display: flex;
  flex-direction: column;
}
.time-box div {
  margin-bottom: 10px;
}
.time-box i {
  color: #a6a6a6;
}
.time-box span {
  color: #000000;
}
.handel-box >>> .el-button--warning {
  color: #e6a23c;
  border-color: #e6a23c;
  background: #fff;
}
.tag-box {
  height: 26px;
  overflow: hidden;
  margin-top: 22px;
}
.tag-box span {
  padding: 3px 14px;
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  margin-right: 12px;
}
.tag-box span:last-child {
  margin-right: 0;
}
.task-status-hand {
  width: calc(100% - 32px);
}
.project-name {
  font-size: 16px;
  font-weight: 600;
  margin: 0 8px;
  display: inline-block;
  max-width: 112px;
  margin-right: 0;
}
</style>